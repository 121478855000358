@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Lora:300,400,500,600,700,800');
@import url('https://css.gg/css');


body {
    background-color: #FAF6EE;
}

a {
    text-decoration: none;
    color: black;
}

// .container {
//     margin: 0 50px;
// }

// .navbar {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;

//     height: 100px;
//     border-bottom: 1px #616161 solid
// }

// .logo {
//     font-family: 'Roboto';
//     font-weight: 800;
//     font-size: 36px;
// }

// .navbar-right, .copyright {
//     font-family: 'Roboto';
//     font-weight: 300;
//     font-size: 16px;
// }

// .hero {
//     margin-top: 30px;
//     display: flex;
//     flex-direction: row;

//     align-items: flex-start;
//     justify-content: space-between;

// }

// .hero-left {
//     width: 50%;
// }

// .hero-text {
//     font-family: 'Lora';
//     font-weight: 500;
//     font-size: 42px;
//     margin-top: 1rem;

//     width: 95%;
// }

// .hero-right {
//     display: flex;
//     flex-direction: column;

//     width: 45%;
// }

// .hero-right-top {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;

//     width: 70%;
//     margin-bottom: 3rem;
// }

// .title {
//     font-family: 'Roboto';
//     font-weight: 300;
//     font-size: 14px;

//     text-transform: uppercase;
// }

// .title-desc {
//     font-family: 'Roboto';
//     font-weight: 500;
//     font-size: 16px;

//     line-height: 1.3em;
//     letter-spacing: 0.005ch;
// }

// .logo-grid {
//     margin-top: 2rem;

//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
//     row-gap: 0rem;
//     column-gap: 1rem;
//     align-items:center;
// }

// .logo-grid-large {
//     width: 50%;
//     padding: 0 20px 0 20px;
// }

// .logo-grid > img {
//     max-width: 100%;
// }

// .footer {
//     margin-top: 3rem;
//     height: 100px;
//     background-color: black;
// }